/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import {
  VIEW_MODE,
  PHARMACY_LAYOUT_USER,
  USER_ROLES
} from '../../helpers/constant';
import { PharmacyCardProps } from '../../helpers/interfaces';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Box,
  Dialog,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import {
  getAddress,
  showFacilityEmail,
  fixCoordinates
} from '../../helpers/utils';
import styles from './PharmacyCard.module.css';
import GeoMap from '../../components/GeoMap/GeoMap';

const PharmacyCard: React.FC<PharmacyCardProps> = (
  props: PharmacyCardProps
): JSX.Element => {
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);

  const state = useSelector(
    (state: RootStateType) => state.pharmaciesLayout
  );

  const pharmacyServicesState = useSelector(
    (state: RootStateType) =>
      state.pharmaciesServicesMapping
  );

  const servicesState = useSelector(
    (state: RootStateType) => state.services
  );

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  let pharmacyServices: any =
    pharmacyServicesState.pharmaciesAndServicesList.filter(
      (service) =>
        service &&
        service.pharmacyRecordId ===
          props.pharmacy.recordId &&
        service.questionType === 'Provider' &&
        service.response !== 'None'
    );

  pharmacyServices = pharmacyServices.map(
    (pharmacyService: any) => ({
      ...pharmacyService,
      serviceName:
        servicesState.servicesList
          .filter(
            (service) =>
              service.serviceId ===
              pharmacyService?.serviceId
          )[0]
          ?.serviceName.trim() ?? '',
      serviceLevel:
        pharmacyService.response.slice(0, 5) +
        ' ' +
        pharmacyService.response.slice(5)
    })
  );

  const showServices = pharmacyServices.length > 0;
  const navigateToPharmacy =
    userState.userProfile &&
    [USER_ROLES.ADMIN, USER_ROLES.SUPERADMIN].includes(
      userState.userProfile.role
    );
  const showMap =
    fixCoordinates(props.pharmacy).locationLatitude *
      10000 !==
      0 &&
    fixCoordinates(props.pharmacy).locationLongitude *
      10000 !==
      0;

  const cardContent = (dialogCard: boolean) => (
    <>
      <CardContent
        className={styles.pharmacyCard}
        style={{
          border: '1px solid rgba(0, 0, 0, 0.2)',
          borderRadius: 8,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease'
        }}
        sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              align="left">
              {props.pharmacy.pharmacyName}
              <Divider
                sx={{ border: '0.5px solid lightgray' }}
              />
            </Typography>
          </Grid>
          {/* Address */}
          <Grid
            item
            xs={12}
            sm={state.mode === VIEW_MODE.GRID_VIEW ? 12 : 7}
            md={
              state.mode === VIEW_MODE.GRID_VIEW ? 12 : 5
            }>
            <Typography align="left" variant="h6">
              {getAddress(
                props.pharmacy.locationAddress1,
                props.pharmacy.locationAddress2,
                props.pharmacy.locationCity,
                props.pharmacy.locationState,
                props.pharmacy.locationZip
              )}
            </Typography>
          </Grid>
          {/* County */}
          <Grid
            item
            xs={12}
            sm={state.mode === VIEW_MODE.GRID_VIEW ? 12 : 4}
            md={
              state.mode === VIEW_MODE.GRID_VIEW ? 12 : 3
            }>
            <Typography align="left" variant="h6">
              <b>County:</b> {props.pharmacy.locationCounty}
            </Typography>
          </Grid>
          {/* Facility Email */}
          {showFacilityEmail(props.pharmacy) && (
            <Grid
              item
              xs={12}
              sm={
                state.mode === VIEW_MODE.GRID_VIEW ? 12 : 7
              }
              md={
                state.mode === VIEW_MODE.GRID_VIEW ? 12 : 4
              }>
              <Typography align="left" variant="h6">
                <b>Email:</b> {props.pharmacy.facilityEmail}
              </Typography>
            </Grid>
          )}
          {/* Phone number */}
          <Grid
            item
            xs={12}
            sm={state.mode === VIEW_MODE.GRID_VIEW ? 12 : 4}
            md={
              state.mode === VIEW_MODE.GRID_VIEW ? 12 : 5
            }>
            <Typography align="left" variant="h6">
              <b>Phone:</b> {props.pharmacy.phone}
            </Typography>
          </Grid>
          {/* Fax */}
          {props.pharmacy.fax && (
            <Grid
              item
              xs={12}
              sm={
                state.mode === VIEW_MODE.GRID_VIEW ? 12 : 7
              }
              md={
                state.mode === VIEW_MODE.GRID_VIEW ? 12 : 3
              }>
              <Typography align="left" variant="h6">
                <b>Fax:</b> {props.pharmacy.fax}
              </Typography>
            </Grid>
          )}
          {/* Hours of operation */}
          {props.pharmacy.operationHours && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography align="left" variant="h6">
                <b>Hours:</b>{' '}
                {props.pharmacy.operationHours}
              </Typography>
            </Grid>
          )}
          {/* Services provided */}
          {showServices && dialogCard && (
            <>
              <Grid item xs={12} md={6}>
                <TableContainer
                  sx={{
                    width: '100%',
                    marginTop: '10px'
                  }}
                  component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <b>Services offered</b>
                        </TableCell>
                        {state.user ===
                          PHARMACY_LAYOUT_USER.PROVIDER && (
                          <TableCell align="left">
                            <b>Service level</b>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody></TableBody>
                    {pharmacyServices.map(
                      (service: any) => {
                        return (
                          <TableRow
                            key={service?.serviceId}
                            sx={{
                              '&:last-child td, &:last-child th':
                                { border: 0 }
                            }}>
                            <TableCell align="left">
                              {service.serviceName}
                            </TableCell>
                            {state.user ===
                              PHARMACY_LAYOUT_USER.PROVIDER && (
                              <TableCell align="left">
                                {service.serviceLevel}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      }
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
          {/* Pharmacy Location */}
          {showMap && dialogCard && (
            <>
              <Grid
                item
                xs={12}
                md={showServices ? 6 : 12}
                sx={{ alignSelf: 'center' }}>
                <Box
                  sx={{
                    height: '400px',
                    width: '80%',
                    maxWidth: '600px',
                    margin: 'auto',
                    marginTop: '10px'
                  }}>
                  <GeoMap pharmacy={props.pharmacy} />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      {props.pharmacy.website && (
        <CardActions sx={{ backgroundColor: '#b0bec5' }}>
          <Tooltip title={props.pharmacy.website}>
            <a
              href={`${
                props.pharmacy.website.includes('http')
                  ? ''
                  : 'https://'
              }${props.pharmacy.website}`}
              target="_blank"
              style={{
                textDecoration: 'none',
                display: 'flex'
              }}
              rel="noopener noreferrer">
              <LanguageTwoToneIcon fontSize="small" />
              <Typography ml={1}>Website</Typography>
            </a>
          </Tooltip>
        </CardActions>
      )}
    </>
  );

  return (
    <>
      <Grid
        item
        key={props.pharmacy.recordId}
        xs={12}
        sm={state.mode === VIEW_MODE.LIST_VIEW ? 12 : 6}
        md={state.mode === VIEW_MODE.LIST_VIEW ? 12 : 4}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (navigateToPharmacy) {
              navigate(
                `/pharmacy/${props.pharmacy.recordId}`
              );
            } else {
              setOpenDialog(true);
            }
          }}>
          {cardContent(false)}
        </Card>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="xl"
          fullWidth>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
            {cardContent(true)}
          </Card>
        </Dialog>
      </Grid>
    </>
  );
};

export default PharmacyCard;
