import {
  MapContainer,
  Marker,
  Popup,
  TileLayer
} from 'react-leaflet';
import { USER_ROLES } from '../../helpers/constant';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  fixCoordinates,
  getAddress
} from '../../helpers/utils';
import { GeoMapProps } from '../../helpers/interfaces';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

const GeoMap: React.FC<GeoMapProps> = (
  props: GeoMapProps
): JSX.Element => {
  const pharmaciesState = useSelector(
    (state: RootStateType) => state.pharmacies
  );
  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const pharmacies = pharmaciesState.pharmaciesList.map(
    (pharmacy) => fixCoordinates(pharmacy)
  );
  let selectedPharmacy = pharmacies.filter(
    (pharmacy) =>
      pharmacy.recordId === props.pharmacy.recordId
  )[0];
  if (selectedPharmacy === undefined) {
    selectedPharmacy = fixCoordinates(props.pharmacy);
  }

  const selectedPharmacyAddress = getAddress(
    selectedPharmacy.locationAddress1,
    selectedPharmacy.locationAddress2,
    selectedPharmacy.locationCity,
    selectedPharmacy.locationState,
    selectedPharmacy.locationZip
  );
  const hideNeighbouringPharmacies =
    userState.userProfile &&
    [
      USER_ROLES.USER,
      USER_ROLES.ADMIN,
      USER_ROLES.SUPERADMIN
    ].includes(userState.userProfile.role);

  const googleMapsLink = `https://www.google.com/maps?q=${selectedPharmacyAddress}`;

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Box sx={{ flexGrow: 1 }}>
        <MapContainer
          center={[
            selectedPharmacy.locationLatitude,
            selectedPharmacy.locationLongitude
          ]}
          zoom={15}
          style={{ height: '100%', width: '100%' }}
          scrollWheelZoom={false}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          {!hideNeighbouringPharmacies &&
            pharmacies.map((pharmacy) => (
              <Marker
                key={pharmacy.recordId}
                eventHandlers={{
                  add: (e) => {
                    if (
                      pharmacy.recordId ===
                      props.pharmacy.recordId
                    ) {
                      const markerIcon =
                        e.target.getElement();
                      if (markerIcon) {
                        markerIcon.style.filter =
                          'hue-rotate(120deg)';
                      }
                    }
                  }
                }}
                position={[
                  pharmacy.locationLatitude,
                  pharmacy.locationLongitude
                ]}>
                <Popup>
                  {pharmacy.pharmacyName}
                  <br />
                  {getAddress(
                    pharmacy.locationAddress1,
                    pharmacy.locationAddress2,
                    pharmacy.locationCity,
                    pharmacy.locationState,
                    pharmacy.locationZip
                  )}
                </Popup>
              </Marker>
            ))}

          {hideNeighbouringPharmacies && (
            <Marker
              eventHandlers={{
                add: (e) => {
                  const markerIcon = e.target.getElement();
                  if (markerIcon) {
                    markerIcon.style.filter =
                      'hue-rotate(120deg)';
                  }
                }
              }}
              position={[
                selectedPharmacy.locationLatitude,
                selectedPharmacy.locationLongitude
              ]}>
              <Popup>
                {selectedPharmacy.pharmacyName}
                <br />
                {getAddress(
                  selectedPharmacy.locationAddress1,
                  selectedPharmacy.locationAddress2,
                  selectedPharmacy.locationCity,
                  selectedPharmacy.locationState,
                  selectedPharmacy.locationZip
                )}
              </Popup>
            </Marker>
          )}
        </MapContainer>
      </Box>
      <Button
        variant="contained"
        size="small"
        sx={{ mt: 1, mb: 1, alignSelf: 'center' }}>
        <Link
          style={{ textDecoration: 'none', color: 'white' }}
          to={googleMapsLink}
          target="_blank">
          Get Directions
        </Link>
      </Button>
    </Box>
  );
};

export default GeoMap;
